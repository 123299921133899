body, html {
  touch-action: manipulation;
  background-color: #212121;
  font-family: "Eventpop", sans-serif;
  font-size: 16px;
  line-height: 24px;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
}

a:focus { 
  outline: none; 
}

@font-face {
  font-family: 'Eventpop';
  src: url('./eventpop-font/Eventpop-Medium.eot');
  src: url('./eventpop-font/Eventpop-Medium.eot?#iefix') format('embedded-opentype'),
       url('./eventpop-font/Eventpop-Medium.woff2') format('woff2'),
       url('./eventpop-font/Eventpop-Medium.woff') format('woff'),
       url('./eventpop-font/Eventpop-Medium.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Eventpop';
  src: url('./eventpop-font/Eventpop-Medium.eot');
  src: url('./eventpop-font/Eventpop-Medium.eot?#iefix') format('embedded-opentype'),
       url('./eventpop-font/Eventpop-Medium.woff2') format('woff2'),
       url('./eventpop-font/Eventpop-Medium.woff') format('woff'),
       url('./eventpop-font/Eventpop-Medium.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Eventpop';
  src: url('./eventpop-font/Eventpop-Bold.eot');
  src: url('./eventpop-font/Eventpop-Bold.eot?#iefix') format('embedded-opentype'),
       url('./eventpop-font/Eventpop-Bold.woff2') format('woff2'),
       url('./eventpop-font/Eventpop-Bold.woff') format('woff'),
       url('./eventpop-font/Eventpop-Bold.ttf') format('truetype');
  font-weight: 700;
}

div#cover {
  border-radius: 150% / 0 0 150px 150px;
  // border-radius:  0 0 25% 25%;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  border-bottom:7px solid white;
  margin-left: -15px;
  margin-right: -15px;
  overflow: hidden;
  height: 300px;
  @media (min-width: 768px) {
    margin-left: 0px;
    margin-right: 0px;
    height: 390px;
  }

  .cover-panel {
    display: block;
    width: 100%;
    height: 300px;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    background-size: cover;
    background-position: top center;

    @media (min-width: 768px) {
      height: 390px;
    }
  }
}

div#logo {
  z-index: 2000;
  position: relative;
  height: 26px;
  margin-bottom: 20px;
  
  img {
    position: absolute;
    left: 0px;
    top: -50px;
    width: 80px;
    height: 80px;
    border: 1px solid #e8e8e8;
    border-radius: 100%;
    box-shadow: 2px 3px 10px rgba(0,0,0,0.2);

    @media (min-width: 768px) {
      width: 90px;
      height: 90px;
      top: -60px;
      left: 20px;
    }
  }
}

#page-maintenance {
  background-color: #fff;
}

#page-home {
  margin-bottom: 150px;
}

.discount-code-box {
  color: #fff;
  margin-top: 20px;
  margin-bottom: 40px;
}

.item-display {
  margin-bottom: 40px;
  transition: margin-bottom 300ms ease-out;
  transition-delay: 200ms;

  &.items-selected {
    margin-bottom: 170px;
    transition-delay: 0ms;
  }

  .item-group {
    margin-bottom: 40px;
    
    .item-group-title {
      color: #fff;
      margin-bottom: 20px;
    }
  }

  .item-group-items {
    list-style-type: none;
    padding-left: 0;

    li.item-group-item {
      position: relative;
      background: #FFFFFF;
      border-radius: 8px;
      border: 5px solid #fff;
    
      padding: 12px 12px 12px 12px;
      margin-bottom: 10px;

      &.item-selected {
        border-color: #ffc002;

        .sales-full-price {
          display: none;
        }

        .item-price {
          font-weight: bold;
        }
      }

      &.item-disabled {
        opacity: 0.5;
        
        .item-details { cursor: default;}
      }

      .item-image, .item-details {
        cursor: pointer;
      }

      .item-details {
        .promotion-badge {
          margin-bottom: 5px;
        }
      }

      .item-name {
        margin: 0;
        font-size: 1.1em;
        margin-bottom: 4px;
      }

      .item-description {
        margin: 0;
        margin-bottom: 10px;
        color: #666;
        font-size: 0.9em;
        line-height: 1.4em;
      }

      .item-price {
        .sales-full-price {
          text-decoration: line-through;
          opacity: 0.5;
        }

        .sales-price {
          color: #dc3545;
          margin-right: 10px;
        }
      }

      .item-price-count {

        .item-price {
          font-size: 1.1em;
          margin: 0;
        }
  
        .item-quantity {
          display: inline-block;
          background: #000106;
          color: #fff;
          border-radius: 4px;
          font-weight: bold;
          font-size: 1em;
          padding: 0 8px;
          margin-left: 6px;
          margin-top: -8px;
        }
  
        .item-minimum-info {
          font-size: 0.8em;
        }
      }

      .item-count .item-count-button {
        position: absolute;
        font-size: 18px;
        line-height: 10px;
        color: #eee;

        &.active-button {
          color: #282c34;
          cursor: pointer;
        }

        &.increment-button {
          top: 0px;
          right: 15px;
        }

        &.decrement-button {
          bottom: 0px;
          right: 15px;
        }
      }
    }
  }
}

.btn-circle {
  width: 36px;
  height: 36px;
  padding: 6px 0px;
  border-radius: 18px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
}

.hidden {
  visibility: hidden;
}

#footer-bar {
  z-index: 3000;
  position: fixed;
  bottom: -80px;
  width: 100%;
  padding: 15px 0 30px 0;
  background-color: #fff;
  border-top: 1px solid #eee;
  transition: all 300ms ease-out;
  transition-delay: 200ms;

  &.show-footer-bar {
    bottom: 0px;
    transition-delay: 0ms;
  }

  .top-mini-bar {
    &.hide-mini-bar {
      height: 0px;
      opacity: 0;
    }
    opacity: 1;
    height: 24px;
    margin: 0px;
    margin-bottom: 15px;
    transition: all 300ms ease-in-out;
    overflow: hidden;

    p {
      font-size: 0.8em;
      margin: 0px;
      display: block;
      width: 100%;
      text-align: center;

    }
  }

  .cart-count {
    color: #999;
    font-size: 0.9em;
    margin-bottom: 0;
  }

  .cart-total {
    margin-bottom: 0;
  }

  .cart-clear-button {
    position: relative;
    top: 1px;
    left: 10px;
    color: firebrick;
    cursor: pointer;
  }

  button {
    width: 100%;
    background-color: #000106;
    transition: opacity 300ms;

    &:disabled {
      opacity: 0.1;
    }
  }
}

#page-terms {
  background-color: #fff;
  padding-bottom: 40px;

  .terms-logo {
    margin-bottom: 20px;
  }

  .terms-decline {
    margin-top: 20px;

    a {
      color: #666;
    }
  }
}