nav#eventpop-navbar {
  min-height: 75px;
  background-color: #fff !important;

  .navbar-brand img {
    height: 27px;

    @media (min-width: 768px) {
      height: 30px;
    }
  }

  .nav-link.active {
    font-weight: bold;
  }
}